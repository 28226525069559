import React from 'react';
import { Container, Row, Card } from 'react-bootstrap';
export default function Sectesti(){
    return (
        <>
            <Container fluid className="__bg-secondary-miror-plc">
                <Container>
                    <Row>
                        <div className="col-lg-12">
                            <div className="__text-testomini-headline">
                                <h2>
                                    Simak Apa Kata Mereka Tentang Commitment Mastery Sebelumnya..
                                </h2>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                        “Jika kamu wanita dan merasakan bahwa menunggu terlalu lama untuk mendapatkan komitmen, terdapat denial atas hubungan saat ini, merasa takut gak laku kalo mutusin pacar sekarang, sebaiknya kamu hilangkan segala bentuk denial tersebut. Ikuti kelas ini”

                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Lita</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-end mt-4">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                        
                                        “Manfaat yang saya dapatkan dari mengikuti training ini sudah mengajarkan saya untuk mengerti bagaimana komitmen itu harus dimiliki oleh kedua belah pihak baik saya sendiri dan pria yang akan dan sudah saya miliki, agar dalam hubungan pernikahan kita tidak merasa kaget dengan pasangan kita.”

                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Magdalenaocy</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                    “Jadi lebih paham apa itu commitment,,, karena yang dibutuhkan adalah commitment bukan hanya status. “
                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Tenri</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-end mt-4">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                        
                                    “ Saya jadi paham bagaimana memperlakukan laki-laki yang bisa mentriggernya untuk komitmen dengan saya. Saya juga menjadi lebih mencintai diri saya sendiri, mengarahkan saya untuk bisa memperbaiki diri dan fokus pada apa yang saya miliki.”

                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Laila</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                    Saya jadi mengerti mengapa saya gagal lanjut hingga komitmen di relasi sebelumnya.<br/><br/>

                                    Saya belajar banyak banget dari training ini. Saya juga terapkan ilmu-ilmu baru yang saya dapat dari training tersebut dan saya merasakan perubahan dari dalam diri saya untuk merasa HAPPY & ENOUGH with myself walaupun saat ini sedang single.<br/><br/> 

                                    Saya merasa MUCH UPGRADED dan tahu bagaimana nantinya bila sudah memiliki calon pasangan potensial lagi supaya bisa men-TRIGGER dia untuk mau dan rela berkomitmen dengan saya. :)”

                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Rahma</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-end mt-4">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                        
                                    “Gak perlu ragu dan sebagainya karena ini sebuah investasi yang sangat berharga buat diri sendiri. Jangan pelit sama diri sendiri, ayo mulai sayangi diri kamu. Ini tidak hanya applicable untuk romansa, tapi untuk networking dan sebagainya. Dapet banget pelajaran hidupnya. Totally worth it! :) “

                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Lidya</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <div className="col-lg-8">
                            <div className="__Card-testimoni">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni-card">
                                    “Coba dulu ikutan, lalu coba praktekkan.. Walaupun nantinya belum bisa dipakai untuk saat ini, bisa jadi bekal untuk di kesempatan nanti.. Dan ini bukan cuma bekal untuk proses sebelum menikah, bahkan bisa dipakai ketika sudah menikah.”
                                    </p>
                                    <p className="__text-testimoni-card-people"><b>Indah</b></p>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

